<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2 ml-4"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Web Category
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="max-height: 90%">
        <v-form v-model="formValid" ref="form">
          <v-row>
                                <v-col cols="12" sm="2" md="2">
              <v-btn
                depressed
                color="primary"
                class="ma-2"
                v-if="!cloneWebCategory && formModel.id"
                @click="handleCloneWebCategory"
              >
                Clone Web category
              </v-btn>
            </v-col>
           
              
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>


 
            </v-col>

   
          </v-row>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  :rules="nameRules"
                  label="Web Category name"
                  :id="dynamicID"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.name']
                  "
                  @keyup="
                    messages['translations.' + selectedLocale.lang + '.name'] =
                      ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div v-if="this.editedItem.id">
                  <!-- <v-select
                    v-model="formModel.web_category_id"
                    :items="parentCategories"
                    item-text="name"
                    item-value="id"
                    label="Parent category"
                    :rules="currencyRules"
                    :id="dynamicID"
                  ></v-select> -->
                  <treeselect
                    class="mt-4 mb-5"
                    label="Parent web category"
                    :options="parentWebCategoriesTree"
                    :default-expand-level="0"
                    placeholder="Parent web category"
                    v-model="formModel.web_category_id"
                  />
                </div>
                <div v-else>
                  <!-- <v-select
                    v-model="formModel.web_category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Parent web category"
                    :rules="currencyRules"
                    :id="dynamicID"
                    ></v-select> -->

                  <!-- :flat="true" -->
                  <treeselect
                    class="mt-4 mb-5"
                    label="Parent web category"
                    :options="webCategoriesTree"
                    :default-expand-level="0"
                    placeholder="Parent web category"
                    v-model="formModel.web_category_id"
                  />
                </div>
                <v-text-field
                  v-if="formModel.id || this.cloneWebCategory"
                  v-model="formModel.translations[selectedLocale.lang].slug"
                  :key="selectedLocale.lang + '-name'"
                  label="Slug"
                  :id="dynamicID"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.slug']
                  "
                  @keyup="
                    messages['translations.' + selectedLocale.lang + '.slug'] =
                      ''
                  "
                ></v-text-field>
                <v-checkbox
                  v-model="formModel.special_template"
                  color="primary"
                  label="List view"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-tabs vertical>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-settings </v-icon>
                  General
                </v-tab>
                <v-tab style="justify-content: flex-start">
                  <v-icon left> mdi mdi-web </v-icon>
                  Web content
                </v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <h5>List of items on this web category</h5>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-autocomplete
                            v-model="autoCompleteItem"
                            :items="filteredItemCollection"
                            item-text="ReferenceName"
                            label="Search item"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-btn
                            depressed
                            color="primary"
                            class="ma-2"
                            @click="handleAddItem"
                            :disabled="!autoCompleteItem.id"
                          >
                            Add item
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-data-table
                            :headers="headers"
                            :items="formModel.itemObjects"
                            :search="filterData.search"
                            class="elevation-1"
                          >
                            <template #header="{ props: { headers } }">
                              <thead class="v-data-table-header">
                                <tr>
                                  <th colspan="2">
                                    <v-text-field
                                      v-model="filterData.search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details
                                    ></v-text-field>
                                  </th>
                                  <th>
                                    <p style="margin-bottom: -16px">
                                      Can be sold
                                    </p>
                                    <v-checkbox v-model="filterData.sale">
                                    </v-checkbox>
                                  </th>
                                  <th>
                                    <p style="margin-bottom: -16px">
                                      Can be manufactured
                                    </p>
                                    <v-checkbox
                                      v-model="filterData.manufacture"
                                    >
                                    </v-checkbox>
                                  </th>
                                  <th>
                                    <p style="margin-bottom: -16px">
                                      Can be purchased
                                    </p>
                                    <v-checkbox v-model="filterData.purchase">
                                    </v-checkbox>
                                  </th>
                                  <th>
                                    <p style="margin-bottom: -16px">Service</p>
                                    <v-checkbox v-model="filterData.service">
                                    </v-checkbox>
                                  </th>
                                  <th>
                                    <v-btn
                                      class="mx-1"
                                      fab
                                      dark
                                      small
                                      color="pink"
                                      @click="
                                        filterData = Object.assign(
                                          {},
                                          initialFilterData
                                        )
                                      "
                                    >
                                      <v-icon dark> mdi-delete </v-icon>
                                    </v-btn>
                                  </th>
                                </tr>
                              </thead>
                            </template>

                            <!-- <template v-slot:item.sale="{ item }">
                              <v-simple-checkbox
                                v-model="item.sale"
                                disabled
                              ></v-simple-checkbox>
                            </template>
                            <template v-slot:item.manufacture="{ item }">
                              <v-simple-checkbox
                                v-model="item.manufacture"
                                disabled
                              ></v-simple-checkbox>
                            </template>
                            <template v-slot:item.purchase="{ item }">
                              <v-simple-checkbox
                                v-model="item.purchase"
                                disabled
                              ></v-simple-checkbox>
                            </template>
                            <template v-slot:item.service="{ item }">
                              <v-simple-checkbox
                                v-model="item.service"
                                disabled
                              ></v-simple-checkbox>
                            </template>
                            <template v-slot:item.action="{ item }">
                              <v-icon small @click="deleteItem(item)">
                                mdi-delete
                              </v-icon>
                            </template> -->
                            <template v-slot:body="props">
                              <draggable
                                :list="props.items"
                                tag="tbody"
                                @update="handleTableUpdate(props.items)"
                              >
                                <tr
                                  v-for="(row, index) in props.items"
                                  :key="index"
                                >
                                  <!-- <td>{{ row.id }}</td> -->
                                  <td>{{ row.reference }}</td>
                                  <td>
                                    {{ row.name }}
                                  </td>
                                  <td>
                                    <v-simple-checkbox
                                      v-model="row.sale"
                                      disabled
                                    ></v-simple-checkbox>
                                  </td>
                                  <td>
                                    <v-simple-checkbox
                                      v-model="row.manufacture"
                                      disabled
                                    ></v-simple-checkbox>
                                  </td>
                                  <td>
                                    <v-simple-checkbox
                                      v-model="row.purchase"
                                      disabled
                                    ></v-simple-checkbox>
                                  </td>
                                  <td>
                                    <v-simple-checkbox
                                      v-model="row.service"
                                      disabled
                                    ></v-simple-checkbox>
                                  </td>
                                  <td>
                                    <v-icon small @click="deleteItem(row)">
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </draggable>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          Description
                          <ckeditor
                            v-if="formModel.translations[selectedLocale.lang]"
                            v-model="
                              formModel.translations[selectedLocale.lang]
                                .description
                            "
                            :error-messages="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.description'
                              ]
                            "
                            @input="
                              messages[
                                'translations.' +
                                  selectedLocale.lang +
                                  '.description'
                              ] = ''
                            "
                            :config="editorConfig"
                          ></ckeditor>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <CustomFieldComponent
                            v-if="
                              formModel.translations[selectedLocale.lang] &&
                              formModel.translations[selectedLocale.lang]
                                .custom_fields
                            "
                            customFieldName="main_image"
                            :value="
                              formModel.translations[selectedLocale.lang]
                                .custom_fields.main_image || ''
                            "
                            :config="{
                              type: 'media_selector',
                              maxFiles: 1,
                              inputFields: [
                                {
                                  name: 'alt_text',
                                  label: 'FORM_INPUT_NAMES.alt_text',
                                  type: 'text',
                                },
                                {
                                  name: 'title',
                                  label: 'FORM_INPUT_NAMES.title',
                                  type: 'text',
                                },
                                {
                                  name: 'description',
                                  label: 'FORM_INPUT_NAMES.description',
                                  type: 'text',
                                },
                              ],
                              selectButtonText: $t(
                                'FORM_INPUT_NAMES.featured_image'
                              ),
                              resolution: '528x704',
                              initialColumnClass:
                                'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12',
                            }"
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                            :calcLength="true"
                            :maxLength="100"
                          >
                          </CustomFieldComponent>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <CustomFieldComponent
                            v-if="
                              formModel.translations[selectedLocale.lang] &&
                              formModel.translations[selectedLocale.lang]
                                .custom_fields
                            "
                            :config="{
                              type: 'text',
                              label: 'FORMS.seo_title',
                            }"
                            customFieldName="seoTitle"
                            :value="
                              formModel.translations[selectedLocale.lang]
                                .custom_fields.seoTitle
                            "
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                            :calcLength="true"
                            :maxLength="60"
                          >
                          </CustomFieldComponent>
                        </v-col>
                        <v-col cols="12">
                          <CustomFieldComponent
                            v-if="
                              formModel.translations[selectedLocale.lang] &&
                              formModel.translations[selectedLocale.lang]
                                .custom_fields
                            "
                            :config="{
                              type: 'textarea',
                              label: 'FORMS.seo_description',
                            }"
                            customFieldName="seoDescription"
                            :value="
                              formModel.translations[selectedLocale.lang]
                                .custom_fields.seoDescription
                            "
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                            :calcLength="true"
                            :maxLength="150"
                          >
                          </CustomFieldComponent>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="8" md="8">
                          <v-row>
                            <v-col cols="12" sm="6" md="6" class="pt-0 mt-3">
                              <CustomFieldComponent
                                v-if="
                                  formModel.translations[selectedLocale.lang] &&
                                  formModel.translations[selectedLocale.lang]
                                    .custom_fields
                                "
                                :config="{
                                  type: 'text',
                                  label: 'FORM_INPUT_NAMES.og_title',
                                }"
                                customFieldName="og_title"
                                :value="
                                  formModel.translations[selectedLocale.lang]
                                    .custom_fields.og_title || ''
                                "
                                @handleChangeCustomFieldValue="
                                  handleChangeCustomFieldValue
                                "
                              >
                              </CustomFieldComponent>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="pt-0 mt-0">
                              <CustomFieldComponent
                                v-if="formModel && formModel.custom_fields"
                                :config="{
                                  type: 'multi_select',
                                  multiple: false,
                                  label: $t('FORM_INPUT_NAMES.og_type'),

                                  items: og_types,
                                }"
                                customFieldName="og_type"
                                :value="
                                  formModel.custom_fields.og_type || 'website'
                                "
                                @handleChangeCustomFieldValue="
                                  handleChangeCustomFieldValue
                                "
                              >
                              </CustomFieldComponent>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <CustomFieldComponent
                                v-if="
                                  formModel.translations[selectedLocale.lang] &&
                                  formModel.translations[selectedLocale.lang]
                                    .custom_fields
                                "
                                :config="{
                                  type: 'textarea',
                                  label: 'FORM_INPUT_NAMES.og_description',
                                }"
                                customFieldName="og_description"
                                :value="
                                  formModel.translations[selectedLocale.lang]
                                    .custom_fields.og_description || ''
                                "
                                @handleChangeCustomFieldValue="
                                  handleChangeCustomFieldValue
                                "
                              >
                              </CustomFieldComponent>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <CustomFieldComponent
                            v-if="
                              formModel.translations[selectedLocale.lang] &&
                              formModel.translations[selectedLocale.lang]
                                .custom_fields
                            "
                            customFieldName="og_image"
                            :value="
                              formModel.translations[selectedLocale.lang]
                                .custom_fields.og_image || ''
                            "
                            :config="{
                              type: 'media_selector',
                              maxFiles: 1,
                              selectButtonText: 'FORM_INPUT_NAMES.og_image',
                              initialColumnClass:
                                'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                              resolution: '1200x630',
                            }"
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                          >
                          </CustomFieldComponent>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-row>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && isNotEditedItem) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./WebCategories";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import draggable from "vuedraggable";
import { categoryTreeMixins } from "@/view/mixins/categoryTreeMixins.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = ["name"];

const initialFilterData = {
  search: "",
  purchase: false,
  sale: false,
  manufacture: false,
  service: false,
};

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "seoTitle",
  "main_image",
  "seoDescription",
  "og_title",
  "og_description",
  "og_url",
  "og_image",
];

export const INITIAL_CUSTOM_FIELDS = { og_type: "website" };

export const initialFormData = () => ({
  id: null,
  status: 1,
  special_template: 0,
  web_categories: [],
  parentCategories: [],
  web_category_id: null,
  translations: {},
  items: [],
  itemObjects: [],
  custom_fields: Object.assign({}, INITIAL_CUSTOM_FIELDS),
});

export default {
  name: "WebCategoryModalForm",
  props: [
    "modalData",
    "editedItem",
    "permissions",
    "categories",
    "itemCollection",
  ],
  components: { CustomFieldComponent, draggable, Treeselect },
  mixins: [formModelMixins, customFieldMixins, categoryTreeMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: initialFormData(),
      formValid: false,
      selectedItem: {},
      dialogDelete: false,
      parentCategories: [],
      filterData: Object.assign({}, initialFilterData),
      initialItems: [],
      messages: {},
      autoCompleteItem: {},
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,
      headers: [
        { text: "Reference", value: "reference" },
        {
          text: "Item name",
          value: "name",
        },
        { text: "Can be sold", value: "sale", searchable: false },
        {
          text: "Can be manufactured",
          value: "manufacture",
          searchable: false,
        },
        { text: "Can be purchased", value: "purchase", searchable: false },
        { text: "Service", value: "service", searchable: false },
        { text: "Action", value: "action", searchable: false },
      ],
      nameRules: [
        (v) => !!v || "Web Category name is required",
        (v) =>
          (!!v && v.length > 2) || "Web Category name must be min 3 characters",
      ],
      og_types: ["website", "article"],
      custom_fields: Object.assign({}, INITIAL_CUSTOM_FIELDS),
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        extraAllowedContent: "iframe[*]",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false,

   

      },
           cloneWebCategory: false,
    };
  },
  computed: {
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    isNotEditedItem() {
      if (this.editedItem.id) {
        return 0;
      } else {
        return 1;
      }
    },
    // ...mapGetters([CURRENCY_COLLECTION]),
    formTitle() {
      if (this.cloneWebCategory) return "Clone Web Category";
      return this.formModel.id ? "Edit Web Category" : "New Web Category";
    },

    filteredItemCollection() {
      if (!this.formModel.itemObjects) return [];
      let itemIds = this.formModel.itemObjects.map((item) => item.id);
      return this.itemCollection.filter((item) => {
        return !itemIds.includes(item.id);
      });
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },
    webCategories() {
      return this.categories;
    },
    parentWebCategoriesTree() {
      if (!this.formModel.id) return [];
      return this.webCategoriesTree.filter(
        (item) => item.id != this.formModel.id
      );
    },
  },

  watch: {
    editedItem(value) {
      this.selectedLocale = i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      });
      this.setParentCategories(this.editedItem.id);
      this.formModel = value;
      if (!value.custom_fields) {
        value.custom_fields = Object.assign({}, this.custom_fields);
      }
      this.initialItems = [...this.formModel.itemObjects];
      this.setTranslatedAttributes();
      this.resetErrorMessages();
    },
    filterData: {
      deep: true,
      handler(value) {
        if (
          !value.manufacture &&
          !value.purchase &&
          !value.sale &&
          !value.service
        ) {
          this.formModel.itemObjects = this.initialItems;
        } else {
          this.formModel.itemObjects = this.formModel.itemObjects.filter(
            (item) => {
              let activeItem = false;
              if (value.manufacture && item.manufacture) {
                activeItem = true;
              }
              if (value.service && item.service) {
                activeItem = true;
              }
              if (value.sale && item.sale) {
                activeItem = true;
              }
              if (value.purchase && item.purchase) {
                activeItem = true;
              }
              return activeItem;
            }
          );
        }
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.resetParentCategories();
      this.$emit("closeModalForm");
      this.cloneWebCategory = false;
    },

    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!this.formValid) return;

      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;

        model.items = model.itemObjects.map((item) => {
          return item.id;
        });

        if (model.id) {
          // alert("PUT " + model.id);
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.cloneWebCategory = false;
              this.$emit("saveModalForm", Object.assign({}, model));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.cloneWebCategory = false;
              this.$emit("saveModalForm", Object.assign({}, data));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
      this.resetParentCategories();
    },

    deleteItem(item) {
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.formModel.itemObjects = this.formModel.itemObjects.filter((item) => {
        return item.id != this.selectedItem.id;
      });
      this.initialItems = this.formModel.itemObjects;
      this.selectedItem = {};
      this.closeDelete();
    },

    closeDelete() {
      this.selectedItem = {};
      this.dialogDelete = false;
    },

    handleNew() {
      this.resetErrorMessages();
      this.setTranslatedAttributes(true);
    },

    handleAddItem() {
      if (this.autoCompleteItem.id) {
        this.formModel.itemObjects.push(this.autoCompleteItem);
        this.initialItems = this.formModel.itemObjects;
        this.autoCompleteItem = {};
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "inventory.itemCategory." + action
      );
    },
    setParentCategories(id) {
      this.parentCategories = this.categories.filter((item) => item.id != id);
    },
    resetParentCategories() {
      this.parentCategories = this.categories;
    },

    handleTableUpdate(items) {
      this.formModel.items = items.map((item) => item.id);

      this.formModel.itemObjects = [...items];
      this.initialItems = [...items];
    },

    handleCloneWebCategory() {
      this.editedItem.id = null;
      this.formModel.id = null;
      this.formModel.isClone = 1;
      this.languages.forEach((language) => {
        this.formModel.translations[language.lang].slug = null;
        this.formModel.translations[language.lang].name += '_clone';

      });
      this.formModel.deletable = true;
      this.cloneWebCategory = true;
    },

  },

  async mounted() {},
};
</script>
